<template>
<div>
  <b-navbar type="dark" variant="primary">
    <b-navbar-brand href="#">
      <router-link to="/"><img src="..\assets\logo_transparent.png" alt="logo"></router-link>
    </b-navbar-brand>

      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/about" class="nav-link">Om mig</b-nav-item>
        <b-nav-item to="/prices" class="nav-link">Priser</b-nav-item>
        <b-nav-item to="/contact" class="nav-link">Kontakt</b-nav-item>
      </b-navbar-nav>
  </b-navbar>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";

 nav {
  height: 75px;
  overflow: hidden;
  background: linear-gradient(71deg, rgba(90,44,186,1) 0%, rgba(142,98,233,1) 16%);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: sticky;
  top: 0;
  img {
    height: 250px;
    margin-top: 20px;
   }
 }
</style>